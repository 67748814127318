// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
	/* Set the font family of the entire app */
	--ion-font-family: 'Roboto', sans-serif;

	--ion-color-primary: #ffffff;
	--ion-color-primary-rgb: 255, 255, 255;
	--ion-color-primary-contrast: #000000;
	--ion-color-primary-contrast-rgb: 0, 0, 0;
	--ion-color-primary-shade: #e0e0e0;
	--ion-color-primary-tint: #ffffff;

	--ion-color-secondary: #000000;
	--ion-color-secondary-rgb: 0, 0, 0;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #000000;
	--ion-color-secondary-tint: #1a1a1a;

	--ion-color-tertiary: #e1261c;
	--ion-color-tertiary-rgb: 225, 38, 28;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #c62119;
	--ion-color-tertiary-tint: #e43c33;

	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45, 211, 111;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0, 0, 0;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255, 196, 9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235, 68, 90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	--ion-color-dark: #191919;
	--ion-color-dark-rgb: 25, 25, 25;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #161616;
	--ion-color-dark-tint: #303030;

	--ion-color-medium: #4C4C4C;
	--ion-color-medium-rgb: 76, 76, 76;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #434343;
	--ion-color-medium-tint: #5e5e5e;

	--ion-color-light: #F6F6F0;
	--ion-color-light-rgb: 246, 246, 240;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d8d8d3;
	--ion-color-light-tint: #f7f7f2;

	--ion-background-color: #191919;
	--ion-background-color-rgb: 25, 25, 25;

	--ion-text-color: #ffffff;
	--ion-text-color-rgb: 255, 255, 255;

	--ion-color-step-50: #252525;
	--ion-color-step-100: #303030;
	--ion-color-step-150: #3c3c3c;
	--ion-color-step-200: #474747;
	--ion-color-step-250: #535353;
	--ion-color-step-300: #5e5e5e;
	--ion-color-step-350: #6a6a6a;
	--ion-color-step-400: #757575;
	--ion-color-step-450: #818181;
	--ion-color-step-500: #8c8c8c;
	--ion-color-step-550: #989898;
	--ion-color-step-600: #a3a3a3;
	--ion-color-step-650: #afafaf;
	--ion-color-step-700: #bababa;
	--ion-color-step-750: #c6c6c6;
	--ion-color-step-800: #d1d1d1;
	--ion-color-step-850: #dddddd;
	--ion-color-step-900: #e8e8e8;
	--ion-color-step-950: #f4f4f4;

}

body {
	.dark {
		--ion-background-color: var(--ion-color-secondary);
		--ion-background-color-rgb: var(--ion-color-secondary-rgb);

		--ion-text-color: var(--ion-color-primary);
		--ion-text-color-rgb: var(--ion-color-primary-rgb);
	}
}

body {
	.light {
		--ion-background-color: var(--ion-color-light);
		--ion-background-color-rgb: var(--ion-color-light-rgb);

		--ion-text-color: var(--ion-color-secondary);
		--ion-text-color-rgb: var(--ion-color-secondary-rgb);
	}
}

ion-button {
	--border-radius: 0;
}

ion-content {	
	.mea-page-content {

		ion-back-button {
			--padding-start: 0px;
			--padding-end: 0px;
		}

		ion-toolbar {
			ion-title {
				padding-left: 5px;
				padding-right: 5px;
			}
		}
	}
}

.danger {
	color: var(--ion-color-danger);
}

ion-toolbar {
	--ion-color: var(--ion-color-primary);
	--ion-text-color-rgb: var(--ion-color-secondary-rgb);
	--ion-toolbar-background: transparent;
	--ion-toolbar-color: var(--ion-color-primary);
	--ion-text-color: var(--ion-color-primary);
}

ion-menu {
	--ion-background-color: var(--ion-color-dark);
	--ion-item-background: var(--ion-color-dark);
	--ion-item-color: var(--ion-color-primary);
	--ion-color: var(--ion-color-primary);
	--ion-text-color-rgb: var(--ion-color-primary-rgb);
	--ion-item-border-color: var(--ion-color-medium);
}

ion-modal {
	&.mea-product-catalog-modal {
		--width: 740px;
		--height: 85vh;

		--ion-background-color: var(--ion-color-primary);
		--ion-background-color-rgb: var(--ion-color-primary-rgb);
		--ion-color: var(--ion-color-secondary);
		--ion-text-color-rgb: var(--ion-color-secondary-rgb);
	}

	&.mea-product-catalog-modal.vertical-display {
		--width: 90vw;
		--height: 70vh;
	}
}

ion-modal {
	&.mea-password-recovery-modal {
		--width: 500px;
		--height: auto;
		--padding: 25px;
	}
}

.ios,
.md {
	& ion-title {
		font-size: 20px;
		font-weight: 500;
		letter-spacing: 0.0125em;
	}
}


.large-vertical-device .md {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: 1rem;
		font-weight: 500;
	}

	h1 {
		margin-top: 2rem !important;
		font-size: 3rem;
		line-height: 3.5rem;
	}

	h2 {
		margin-top: 2rem !important;
		font-size: 2.5rem;
		line-height: 3rem;
	}

	h3 {
		margin-top: 1rem;
		font-size: 1.8rem !important;
		line-height: 2.5rem;
	}

	h4 {
		margin-top: 1rem;
		font-size: 1.4rem !important;
		line-height: 2rem;
	}

	h5 {
		margin-top: 1rem;
		font-size: 1.4rem !important;
		line-height: 2rem;
	}

	h6 {
		margin-top: 1rem;
		font-size: 1.4rem !important;
		line-height: 2rem;
	}

	span,
	p,
	div,
	a {
		font-size: 2rem !important;
	}

	ion-content {
		.mea-page-content {
			ion-toolbar {
				ion-title {
					font-size: 3rem !important;
				}
			}
		}
	}

	ion-back-button {
		margin-top: 2rem;
		--icon-font-size: 2rem !important;
		font-size: 2rem !important;
	}

	&.mea-page-description {
		ion-card-content {
			font-size: 2.5rem !important;
		}

		ion-card-title {
			font-size: 3rem !important;
		}
	}

	.mea-catalog-navigation--items {
		ion-back-button {
			--icon-font-size: 2rem !important;
			font-size: 2rem !important;
			line-height: 2.5rem !important;
		}
	}

	ion-card {
		min-height: 300px !important;
		padding-left: 1rem;
		padding-right: 1rem;
		.ion-title{
			line-height: 3rem !important;
			font-size: 2.5rem !important;
		}
	}

	ion-title {
		font-size: 3rem !important;
		font-weight: 500;
		letter-spacing: 0.1em;
	}
}